.featured-section {
  max-width: 1200px;
  margin: 0 auto;
  display: grid;
  grid-gap: 1rem;
  grid-template-columns: repeat(auto-fit, minmax(300px, 1fr));

  align-items: center;
  img {
    //
    clip-path: inset(100% 0% 0% 0%);
    transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
    &.is-reveal {
      clip-path: inset(0% 0% 0% 0%);
    }
  }

  h6 {
    font-size: 22px;
    text-transform: uppercase;
    font-weight: 500;
  }

  //
  //-------------------------
  //
  .featured-row-layout,
  .featured-column-layout {
    display: grid;
    gap: 10px;
    // background-color: orange;
  }

  .featured-row-layout {
    // https://travishorn.com/responsive-grid-in-2-minutes-with-css-grid-layout-4842a41420fe
    // Is the screen larger than 600 pixels? Make it two columns.
    grid-template-rows: repeat(2, auto);

    img {
      width: 100%;
      // height: 100%;
      object-fit: cover;
    }
  }
  .featured-column-layout {
    grid-template-columns: 50px auto;
    align-items: flex-end; //will align the text to the bottom

    img {
      //
      width: 100%;
      height: 45vh; //img height
      object-fit: cover;
    }
    h6 {
      //translateX:will move the text to the x dir
      transform: translateX(100%) rotate(-90deg);
      transform-origin: left bottom;
      justify-self: self-end;
    }
  }
}

@media screen and (min-width: 768px) {
  .featured-section {
    display: grid;
    grid-template-columns: 1fr 3fr;

    align-items: center;
    img {
      //
      clip-path: inset(100% 0% 0% 0%);
      transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
      &.is-reveal {
        clip-path: inset(0% 0% 0% 0%);
      }
    }

    h6 {
      font-size: 22px;
      text-transform: uppercase;
      font-weight: 500;
    }

    //
    //-------------------------
    //
    .featured-row-layout,
    .featured-column-layout {
      display: grid;
      gap: 10px;
      // background-color: orange;
    }

    .featured-row-layout {
      grid-template-rows: repeat(2, auto);

      img {
        //
        width: 100%;
        // height: 100%;
        object-fit: cover;
      }
    }
    .featured-column-layout {
      grid-template-columns: 100px auto;
      align-items: flex-end; //will align the text to the bottom

      img {
        width: 100%;
        height: 125vh;
        object-fit: cover;
      }
      h6 {
        //translateX:will move the text to the x dir
        transform: translateX(100%) rotate(-90deg);
        transform-origin: left bottom;
        justify-self: self-end;
      }
    }
  }
}
