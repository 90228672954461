.thankyou-container {
  text-align: center;
  padding-bottom: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  .location {
    font-size: calc(12px + 1vmin);
    line-height: calc(12px + 1vmin);
    text-transform: uppercase;
    font-family: "Poppins-Light";
    font-style: normal;
    opacity: 0;

    word-spacing: 2px;
    // span
    .sp-thank {
      font-style: italic;
      margin: 0 3px;
      font-family: "Bodoni Moda", serif;
      font-family: "Casta-Thin";

      font-size: calc(10px + 1vmin);
    }

    //
    //
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      // background-color: orange;
      max-width: 400px;
      font-size: calc(40px + 1vmin);
      line-height: calc(45px + 1vmin);
      word-wrap: break-word;
      .sp-thank {
        font-size: calc(45px + 1vmin);
      }
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      // background-color: pink;
      word-wrap: break-word;
      max-width: 400px;
      font-size: calc(40px + 1vmin);
      line-height: calc(45px + 1vmin);
      .sp-thank {
        font-size: calc(45px + 1vmin);
      }
    }
    //
    //
    &.is-reveal {
      opacity: 1;
    }
  }
}
