/*---------Poppins-Light---------*/
@font-face {
  src: url(../fonts/Poppins-Light.ttf);
  font-family: "Poppins-Light";
}
/*---------PoppinsThin---------*/
@font-face {
  src: url(../fonts/Poppins-Thin.ttf);
  font-family: "PoppinsThin";
}
/*--------- CASTA ---------*/

@font-face {
  src: url(../fonts/Casta-Thin.otf);
  font-family: "Casta-Thin";
}
/*---------PoppinsThin---------*/
@font-face {
  src: url(../fonts/Casta-ThinSlanted.otf);
  font-family: "Casta-Thin-oblique";
}
@font-face {
  src: url(../fonts/Wagon-ExtraLightItalic.otf);
  font-family: "Wagon-Italic";
}
/*--------- CASTA ---------*/
@font-face {
  src: url(../fonts/Wagon-ExtraLight.otf);
  font-family: "Wagon-ExtraLight";
}
