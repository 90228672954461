#svg-wrapper-gallery {
  margin: 0;
  padding: 0;
  position: relative;

  // background-color: red;

  // -------- **
  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
  @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
    padding-top: 70px;
  }
  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
  @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
    font-size: calc(45px + 1.1vmin);
    padding-top: 70px;
  }
  //
  //
  //
  //
  // absolute
  //
  //
  .wrapper-bio-top-absolute {
    position: absolute;
    top: 50%;
    left: 25%;
    z-index: 899;
    // -------- **
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
      top: 35%;
      left: 20%;
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
    @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
      font-size: calc(45px + 1.1vmin);
      top: 45%;
      left: 22%;
    }
    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      top: 50%;
      left: 25%;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      top: 50%;
      left: 25%;
    }
    //
    //

    .header-container {
      position: relative;
      // background-color: #f0f;

      display: flex;
      justify-content: center;
      align-items: center;

      // h1
      .header-text {
        padding-top: 50px;
        // background-color: orange;
        width: 100%;
        font-family: "Casta-Thin-oblique";
        font-size: calc(150px + 1.1vmin);
        text-transform: uppercase;
        max-width: 200px;

        font-weight: 600;
        text-align: left;

        // -------- **
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
          font-size: calc(45px + 1.1vmin);
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
        @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
          font-size: calc(95px + 1.1vmin);
        }
        // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-width: 992px) and (min-width: 769px) and (orientation: portrait) {
        }
        //
        // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-height: 992px) and (min-width: 769px) and (orientation: landscape) {
        }
        //-------------**
        //-------------**
        // TABLET2 - PORTRAIT 994 x 1367
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        }

        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          font-size: calc(150px + 1.1vmin);
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          font-size: calc(150px + 1.1vmin);
        }

        // DESKTOP large / 1920 x 1080 (mine) - PORTRAIT
        @media only screen and (max-width: 1966px) and (min-width: 1667px) and (orientation: portrait) {
        }
        // DESKTOP large / 1920 x 1080 (mine) - LANDSCAPE
        @media only screen and (max-height: 1966px) and (min-width: 1667px) and (orientation: landscape) {
        }
      }
    }
    //Gsap related
    .lineParent {
      overflow: hidden;
    }
    //GSAP RELATED 5
    .lineChildren {
      transform: translate(0, 400px);
    }
    //
  }
  .svg-container-gallery {
    display: flex;
    justify-content: center;
    align-items: center;
    //@at-root
    .svg-img-column {
      display: inline-block;
      position: relative;
      width: 60%;
      padding-bottom: 60%;
      vertical-align: middle;
      overflow: hidden;

      .svg-content {
        display: inline-block;
        position: absolute;
        top: 0;
        left: 0;
      }
      .img-box {
        width: 100%;
        min-height: auto;
        object-fit: cover;
      }
    }
  }
}

/*


#svg-container-gallery {
  display: grid;
  grid-template-columns: 1fr 3fr;


  align-items: center;
  background-color: pink;

  .svg-img-column,
  .svg-img-row {
    display: grid;
    gap: 10px;
  }
  //
  //

  .svg-img-row {
    background-color: rgb(173, 13, 39);

    grid-template-rows: repeat(3, auto);

    clip-path: inset(0% 0% 100% 0%); //the side from where you want the reveal
    //clip-path 2s cubic how fast you want the reveal to happen
    transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
    &.is-reveal {
      clip-path: inset(0% 0% 0% 0%);
    }
  }
  //@at-root
  .svg-img-column {
    background-color: rgb(173, 98, 13);

    grid-template-columns: 300px auto;
    align-items: flex-end;

    clip-path: inset(0% 0% 100% 0%); //the side from where you want the reveal
    //clip-path 2s cubic how fast you want the reveal to happen
    transition: clip-path 1s cubic-bezier(0.77, 0, 0.175, 1);
    &.is-reveal {
      clip-path: inset(0% 0% 0% 0%);
    }
  }
}




*/
