//header
.navigation {
  // padding: 20px 0 40px 0;
  top: 0;
  left: 0;
  position: fixed;
  //
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  //
  // background: palegoldenrod;
  z-index: 1;
  letter-spacing: 1px;
  font-family: "Syncopate", sans-serif;
  color: #181818;

  text-transform: uppercase;
  height: 100%;
  width: 100%;
  max-width: 2500px;

  //
  nav {
    width: 98%;
    height: 3vh;
    margin: 0 auto;
    background-color: rgb(251, 246, 233);
    // padding: 1vh 0;
    z-index: 999;
    .grid-1-nav {
      background-color: rgb(251, 246, 233);
      // background-color: red;
      border-bottom: 1.5px solid #000;
      // background-color: rgba(255, 17, 17, 0.914);
      // padding: 40px 0;
      // max-width: 1980px;
      width: 100%;
      height: 100%;
      margin: 0 auto;
      padding: 10px 0;

      //
      //
      display: grid;
      grid-gap: 5px;
      grid-template-columns: 1fr 2fr 1fr;

      // -------- **
      // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
      @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
        grid-template-columns: 0.5fr 1fr 0.5fr;
      }
      // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
      @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
        grid-template-columns: 0.5fr 1fr 0.5fr;
      }

      //-------------**
      //-------------**
      // DESKTOP - PORTRAIT
      @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
        grid-template-columns: 0.5fr 0.5fr 0.5fr;
      }

      // DESKTOP - LANDSCAPE
      @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
        grid-template-columns: 0.5fr 0.5fr 0.5fr;
      }

      //

      //
      //
      //
      //pointer-events: none;
      .top-grid-1-nav,
      .left-grid-1-nav,
      .right-grid-1-nav {
        // background-color: saddlebrown;
        display: flex;
        justify-content: center;
        align-items: center;
        font-weight: 500;
      }
      // about
      .top-grid-1-nav {
        justify-self: flex-start;
        background-color: rgb(251, 246, 233);

        z-index: 999;

        cursor: pointer;
        padding: 0px 20px;
        border-radius: 50px;
        border: 1px solid #000;

        // background-color: orange;

        // -------- **
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
          font-size: calc(5px + 1vmin);
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
        @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
          font-size: calc(5px + 1vmin);
        }
      }

      //
      // logo
      .left-grid-1-nav {
        justify-self: center;
        font-weight: 600;
        // background-color: pink;
        font-size: calc(10px + 1vmin);
        font-style: italic;
        font-family: "Wagon-ExtraLight";
        letter-spacing: 2px;

        // -------- **
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
          justify-self: center;
          text-align: center;
          font-size: calc(5px + 1vmin);
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
        @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
          justify-self: center;
          text-align: center;
          font-size: calc(5px + 1vmin);
        }

        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          text-align: center;
          justify-self: center;
          font-size: calc(10px + 1vmin);
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          text-align: center;
          justify-self: center;
          font-size: calc(10px + 1vmin);
        }

        //
      }

      // portfolio
      .right-grid-1-nav {
        justify-self: flex-end;
        z-index: 999;
        pointer-events: all;
        // background-color: khaki;
        cursor: pointer;
        background-color: rgb(251, 246, 233);
        padding: 0px 20px;
        border-radius: 50px;
        border: 1px solid #000;

        // -------- **
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
          font-size: calc(5px + 1vmin);
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
        @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
          font-size: calc(5px + 1vmin);
        }
      }
    }

    //
    //
    //
  }

  //
  // ** GRID 2 🟠

  .grid-2-nav {
    border-bottom: 1.5px solid #000;
    // background-color: green;
    // background-color: rgba(244, 217, 14, 0.426);
    // padding: 40px 0;
    // max-width: 1980px;
    width: 98%;
    height: 180%;
    margin: 0 auto;
    z-index: 888;
    //
    //
    display: grid;
    grid-gap: 5px;
    grid-template-columns: 0.5fr 0.5fr;

    // -------- **
    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
    @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
      grid-template-columns: 0.5fr 0.5fr 0.5fr;
    }
    // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
    @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
      grid-template-columns: 0.5fr 0.5fr 0.5fr;
    }

    //-------------**
    //-------------**
    // DESKTOP - PORTRAIT
    @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
      grid-template-columns: 0.5fr 0.5fr 0.5fr;
    }

    // DESKTOP - LANDSCAPE
    @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
      grid-template-columns: 0.5fr 0.5fr 0.5fr;
    }

    .social-1 {
      //
      border-left: 1.5px solid #000;
      border-right: 1.5px solid #000;
      width: 7%;
      height: 100%;
      // background-color: pink;
      display: flex;
      justify-content: flex-start;
      align-items: flex-end;

      // -------- **
      // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
      @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
        width: 50%;
      }
      // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
      @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
        width: 50%;
      }

      //
      //
      // icons
      .folio-3D {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30px;
        height: 30px;
        margin-bottom: 5px;
        margin-left: 10px;

        button {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 100%;
          height: 100%;
          border: 1.5px solid #000;
          border-radius: 100px;
          color: antiquewhite;
          background: #181818;
          transition: all 1s ease-in-out;
          &:hover {
            color: #181818;
            background-color: transparent;
          }

          // transform: rotate(80deg);
        }
      }
    }

    //  💟
    .social-2 {
      border-left: 1px solid #000;
      // background-color: orange;
      display: flex;
      flex-direction: row;
      justify-content: center;
      align-items: flex-end;

      // background-color: rgb(194, 74, 94);
      //
      //
      .copyright-social {
        display: flex;
        flex-direction: row;

        justify-content: center;
        align-items: center;
        width: 100%;
        p {
          display: flex;
          flex-direction: row;
          text-align: center;
          // -------- **
          // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
          @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
            justify-self: flex-start;
            font-size: calc(5px + 1vmin);
          }
          // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
          @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
            justify-self: center;
            font-size: calc(5px + 1vmin);
          }

          // DESKTOP - PORTRAIT
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            text-align: center;
            justify-self: center;
            font-size: calc(8px + 1vmin);
          }

          // DESKTOP - LANDSCAPE
          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            text-align: center;
            justify-self: center;
            font-size: calc(8px + 1vmin);
          }
        }
      }
    }
    //  ---

    //  💟
    .social-3 {
      border-left: 1px solid #000;
      // background-color: orange;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      align-items: flex-end;

      // background-color: pink;
      //

      // icons
      .social-icons-container {
        //
        //
        border-left: 1.5px solid #000;
        border-right: 1.5px solid #000;
        width: 30%;
        height: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
        align-items: center;

        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
          width: 60%;
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
        @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
          width: 60%;
        }

        // TABLET2 - PORTRAIT 994 x 1367
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
        }

        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          width: 15%;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          width: 15%;
        }
        //
        //
        //
        .social-icon {
          display: flex;
          justify-content: center;
          align-items: center;
          width: 30px;
          height: 30px;
          margin-bottom: 5px;

          button {
            display: flex;
            justify-content: center;
            align-items: center;
            width: 100%;
            height: 100%;
            border: 1.5px solid #000;
            border-radius: 100px;
            background-color: transparent;
            // transform: rotate(80deg);
          }
        }
      }
    }

    //
    //
  }
}
