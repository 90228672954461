// @import url("https://fonts.googleapis.com/css?family=Oswald:300;700&display=swap");
// @import url("https://fonts.googleapis.com/css2?family=Merriweather:wght@300;400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Raleway:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100;200;300;400;500;600;700;800;900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bai+Jamjuree:wght@100;500;600;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Syncopate:wght@400;700&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Roboto:ital,wght@0,100;0,300;0,400;0,700;0,900;1,100;1,400;1,500;1,900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Bodoni+Moda:wght@100;700&display=swap");

//

@import "_fonts";
//

// $bg-color: #f5f0ec;
//
*,
*::before,
*::after {
  margin: 0;
  padding: 0;

  text-decoration: none;
  list-style: none;
  outline: none;
  // -----------
  -webkit-touch-callout: none; /* iOS Safari */
  -webkit-user-select: none; /* Safari */
  -khtml-user-select: none; /* Konqueror HTML */
  -moz-user-select: none; /* Old versions of Firefox */
  -ms-user-select: none; /* Internet Explorer/Edge */
  user-select: none; /* Non-prefixed version, currently
                              supported by Chrome, Edge, Opera and Firefox */
  // disable image drag ----
  // -----------------------
}
//

body {
  // the default font for the whole body will be this one below:
  font-family: "Poppins", sans-serif;
  //   cursor: none; // if you unhide this, the cursor will dissapear
  // when you hover in the 'body'
  //   background-color: #ffe4cb;

  //
  overflow-x: hidden;
}
.page {
  margin: 0 auto;
  max-width: 2500px;
  min-height: 120vh;
  .main-container {
    padding: 0 5vw; //this will control the margin right and left
    //@at-root
    //@at-root
    -webkit-animation: fadein 3s; /* Safari, Chrome and Opera > 12.1 */
    -moz-animation: fadein 3s; /* Firefox < 16 */
    -ms-animation: fadein 3s; /* Internet Explorer */
    -o-animation: fadein 3s; /* Opera < 12.1 */
    animation: fadein 3s;

    background-color: rgb(251, 246, 233);
  }
}

//
//
section {
  padding: 100px 0; //100 from top and bottom, 0 left and 0 to right
  .section-header {
    margin-bottom: 50px;
    //background-color: #ff0000; //about red line in the bio welcome
  }
}
.absolute {
  // with this it will take the full width
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: black;
  z-index: 1;
}

.loader-wrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  flex-direction: column;
  background-color: #191919;

  color: rgb(248, 248, 248);

  h1 {
    font-size: calc(5px + 2vmin);
    text-transform: uppercase;
    font-family: "Bai Jamjuree", sans-serif;
    font-weight: 600;
    line-height: 0.1em;
  }
  h2 {
    font-size: calc(5px + 2vmin);
    text-transform: uppercase;
    font-family: "Bodoni Moda", serif;
    font-style: italic;
    margin-top: 10px;
  }
}

@keyframes fadein {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}
