#modal-overlay-wrapper {
  width: 100vw;
  min-height: 100vh;

  z-index: 9999;
  position: fixed;

  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: rgb(248, 248, 248);

  .modal-overlay-content {
    // background-color: rgb(228, 188, 127);
    width: 100%;
    height: 100%;
    background-color: rgb(251, 246, 233);

    //
    .overlay {
      .wrapper-img-and-text {
        // display: flex;
        // justify-content: center;
        // align-items: center;
        // flex-flow: row-reverse;
        border-top: 1.5px solid #000;

        width: 100%;
        height: 100vh;
        margin: 0 auto;

        //
        //
        display: grid;
        grid-gap: 20px;
        grid-template-columns: 1.2fr 2fr;

        //

        // -------- **
        // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
        @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
          grid-template-columns: 1fr;
        }
        // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
        @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
          grid-template-columns: 1.2fr 2fr;
          grid-gap: 5px;
        }
        // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-width: 992px) and (min-width: 769px) and (orientation: portrait) {
          grid-template-columns: 1fr;
        }
        //
        // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
        @media only screen and (max-height: 992px) and (min-width: 769px) and (orientation: landscape) {
          grid-template-columns: 1.2fr 2fr;
        }
        //-------------**
        //-------------**
        // TABLET2 - PORTRAIT 994 x 1367
        @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
          grid-template-columns: 1.2fr 2fr;
        }

        // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
        @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
          grid-template-columns: 1.2fr 2fr;
        }

        //-------------**
        //-------------**
        // DESKTOP - PORTRAIT
        @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
          grid-template-columns: 1.2fr 2fr;
        }

        // DESKTOP - LANDSCAPE
        @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
          grid-template-columns: 1.2fr 2fr;
        }
        //-------------**
        //-------------**

        /*



        */
        //
        //  BLOCK 0 🟩
        .block-0 {
          border-right: 1.5px solid #000;
          width: 100%;

          min-height: 100vh;
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          gap: 12px;

          // -------- **
          // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
          @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
            min-height: 20vh;
            gap: 5px;
          }
          // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
          @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
            min-height: 20vh;
            gap: 5px;
          }
          // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-width: 992px) and (min-width: 769px) and (orientation: portrait) {
            min-height: 40vh;
          }
          //
          // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-height: 992px) and (min-width: 769px) and (orientation: landscape) {
            min-height: 40vh;
          }
          //-------------**
          //-------------**
          // TABLET2 - PORTRAIT 994 x 1367
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            min-height: 40vh;
          }

          // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            min-height: 40vh;
          }

          //-------------**
          //-------------**
          // DESKTOP - PORTRAIT
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            min-height: 100vh;
          }

          // DESKTOP - LANDSCAPE
          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            min-height: 100vh;
          }
          //-------------**
          //-------------**

          // -------- **
        }

        //
        //🙂
        .block1 {
          display: flex;
          flex-direction: column;
          min-height: 100vh;

          // -------- **
          // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
          @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
            min-height: 60vh;
          }
          // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
          @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
            min-height: 60vh;
          }
          // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-width: 992px) and (min-width: 769px) and (orientation: portrait) {
            min-height: 60vh;
          }
          //
          // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
          @media only screen and (max-height: 992px) and (min-width: 769px) and (orientation: landscape) {
            min-height: 60vh;
          }
          //-------------**
          //-------------**
          // TABLET2 - PORTRAIT 994 x 1367
          @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
            min-height: 60vh;
          }

          // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
          @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
            min-height: 60vh;
          }

          //-------------**
          //-------------**
          // DESKTOP - PORTRAIT
          @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
            min-height: 100vh;
          }

          // DESKTOP - LANDSCAPE
          @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
            min-height: 100vh;
          }

          .block-bar {
            width: 100%;
            // height: 15vh;
            display: flex;
            justify-content: center;
            align-items: center;

            //
            //
            .container-top-close {
              width: 100%;
              height: 100%;
              margin: 0 auto;
              border-bottom: 1.5px solid #000;

              //
              //
              display: grid;
              grid-gap: 0px;
              grid-template-columns: 2fr 1fr;

              .title-box {
                display: flex;
                align-items: center;
                h1 {
                  max-width: 80%;
                  word-wrap: break-word;

                  font-size: calc(40px + 1vmin);
                  line-height: calc(45px + 1vmin);
                  font-family: "Wagon-ExtraLight";
                  letter-spacing: 5px;
                  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
                  @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
                    font-size: calc(10px + 1.1vmin);
                  }
                  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
                  @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
                    font-size: calc(15px + 1.1vmin);
                  }

                  //-------------**
                  //-------------**
                  // DESKTOP - PORTRAIT
                  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                    font-size: calc(40px + 1vmin);
                  }

                  // DESKTOP - LANDSCAPE
                  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                    line-height: calc(45px + 1vmin);
                  }
                  //
                }
              }

              //
              // ** CLOSE
              .contain-btn-close-mobile {
                display: flex;
                justify-content: center;
                align-items: center;
                align-self: flex-end;
                justify-self: flex-end;

                width: 100%;
                //
                //
                .btn-close-mobi {
                  background-color: rgb(18, 18, 18);
                  color: rgb(251, 246, 233);
                  border-radius: 50px;
                  border: 1px solid #000;
                  margin-right: 10px;
                  padding: 3px;
                  width: 30px;
                  height: 30px;
                  cursor: pointer;

                  display: flex;
                  justify-content: center;
                  align-items: center;
                  transition: 1s all ease-in-out;

                  &:hover {
                    background-color: transparent;
                    color: #191919;
                  }

                  //
                  //
                  //
                  .btn-close {
                    z-index: 999;
                    width: 100%;
                    height: 100%;
                  }
                }
                // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
                @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
                  width: 50px;
                  height: 50px;
                }
                // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
                @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
                  width: 50px;
                  height: 50px;
                }
              }
            }
          }

          //👱‍♂️
          .block_bottom {
            border-top: 1.5px solid #000;
            border-bottom: 1.5px solid #000;
            //
            width: 100%;
            height: 100%;
            margin: 0 auto;
            padding: 10px 0;

            //
            display: grid;
            grid-gap: 10px;
            grid-template-columns: 1fr;
            //
            //

            //
            //-------------**
            //-------------**
            //-------------**
            // TABLET2 - PORTRAIT 994 x 1367
            @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
              grid-template-columns: 1fr 1fr;
            }

            // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
            @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
              grid-template-columns: 1fr 1fr;
            }
            // DESKTOP - PORTRAIT
            @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
              grid-template-columns: 1fr 1fr;
            }

            // DESKTOP - LANDSCAPE
            @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
              grid-template-columns: 1fr 1fr;
            }
            //
            //
            //
            //
            .block-left {
              padding: 0 10px;
              border-top: 1.5px solid #000;
              position: relative; //
              .emblem {
                position: absolute;
                left: 0;
                right: -100px;
                top: 20vh;

                //
                //
                z-index: 999;
                margin: 0 auto;
                width: 25vh;
                height: 25vh;
                border-radius: 50%;

                font-weight: bold;
                color: #bfc8cbc2;
                animation: spinZ 20s linear infinite;
                text-align: center;

                // -------- **
                // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
                @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
                  right: -150px;
                  top: 20vh;
                }
                // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
                @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
                  right: -150px;
                  top: 20vh;
                }

                // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
                @media only screen and (max-width: 992px) and (min-width: 769px) and (orientation: portrait) {
                  right: -300px;
                  top: 25vh;
                }
                //
                // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
                @media only screen and (max-height: 992px) and (min-width: 769px) and (orientation: landscape) {
                  right: -300px;
                  top: 25vh;
                }

                //-------------**
                //-------------**

                //-------------**
                // TABLET2 - PORTRAIT 994 x 1367
                @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                  right: -300px;
                  top: 45vh;
                }

                // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                  right: -300px;
                  top: 45vh;
                }
                //-------------**
                //-------------**
                // DESKTOP - PORTRAIT
                @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                  display: none;
                  right: -100px;
                  top: 35vh;
                }

                // DESKTOP - LANDSCAPE
                @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                  display: none;
                  right: -200px;
                  top: 45vh;
                }
                //
                //
                span {
                  position: absolute;
                  display: inline-block;
                  left: 0;
                  right: 0;
                  top: 0;
                  bottom: 0;
                  text-transform: uppercase;
                  font-size: 5vh;
                  font-family: "Wagon-Italic";
                  font-size: calc(15px + 1vmin);
                  transition: all 0.5s cubic-bezier(0, 0, 0, 1);
                }

                @keyframes spinZ {
                  0% {
                    transform: rotateZ(360deg);
                  }
                  100% {
                    transform: rotateZ(0deg);
                  }
                }
              }
              //

              display: flex;
              flex-direction: column;
              justify-content: space-between;
              align-items: flex-start;
              // --------
              // -------- **
              // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
              @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
                justify-content: space-around;
              }
              // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
              @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
                justify-content: space-around;
              }

              //-------------**
              //-------------**
              // DESKTOP - PORTRAIT
              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                justify-content: space-between;
              }

              // DESKTOP - LANDSCAPE
              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                justify-content: space-between;
              }
              //
              //

              //
              // -------- **

              //
              // ** DESCRIPTION
              .wrapper-text-items {
                // background-color: beige;
                // text
                // title
                h1 {
                  padding: 20px 0 10px 0;
                  font-size: calc(10px + 1.1vmin);
                  letter-spacing: 3px;
                  font-family: "Casta-Thin";
                  font-weight: 600;
                }

                p {
                  padding: 5px 0;
                  text-align: left;
                  width: 90%;
                  font-size: calc(6px + 1.1vmin);
                  // DESKTOP - PORTRAIT
                  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                    font-size: calc(8px + 1.1vmin);
                  }

                  // DESKTOP - LANDSCAPE
                  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                    font-size: calc(8px + 1.1vmin);
                  }
                  //
                }
              }

              // ** STACK
              .ul-items {
                width: 100%;
                height: auto;
                margin: 0 auto;

                padding: 20px 0 5px 0;

                display: grid;
                grid-gap: 10px;
                grid-template-columns: 1fr;

                h1 {
                  font-size: calc(10px + 1.1vmin);
                  letter-spacing: 3px;
                  font-family: "Casta-Thin";
                  font-weight: 600;
                }

                .ul-container {
                  display: flex;
                  flex-direction: column;

                  // -------- **
                  // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
                  @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
                    flex-direction: column;
                  }
                  // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
                  @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
                    flex-direction: column;
                  }
                  // TABLET1 - PORTRAIT 768 x 1024   (http://responsivetesttool.com/?url=http://localhost:7521/)
                  @media only screen and (max-width: 992px) and (min-width: 769px) and (orientation: portrait) {
                    flex-direction: column;
                  }
                  //
                  // TABLET1 - LANDSCAPE 991 x 568 (http://responsivetesttool.com/?url=http://localhost:7521/)
                  @media only screen and (max-height: 992px) and (min-width: 769px) and (orientation: landscape) {
                    flex-direction: column;
                  }

                  //-------------**
                  //-------------**

                  //-------------**
                  // TABLET2 - PORTRAIT 994 x 1367
                  @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                    flex-direction: column;
                  }

                  // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
                  @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                    flex-direction: column;
                  }

                  // DESKTOP - PORTRAIT
                  @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                    flex-direction: column;
                  }

                  // DESKTOP - LANDSCAPE
                  @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                    flex-direction: column;
                  }
                  //
                  //
                  li {
                    font-size: calc(5px + 1.1vmin);

                    font-family: "Poppins-Light";
                  }
                }
              }
              // ------
              //
              // ** ARROWS
              // ---------
              .footer-modal {
                width: 100%;
                display: grid;
                grid-gap: 10px;
                grid-template-columns: 1fr 1fr;

                // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
                @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
                  width: 100%;
                  grid-template-columns: 1fr 1fr;
                }
                // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
                @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
                  width: 100%;
                  grid-template-columns: 1fr 1fr;
                }

                // ARROWS
                .container-arrow {
                  display: flex;
                  justify-content: space-between;
                  align-items: center;

                  button {
                    z-index: 999;
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    background-color: transparent;

                    border-radius: 50px;
                    border: 1px solid #000;

                    padding: 3px 20px;
                    width: 100px;
                    cursor: pointer;
                    // MOBILE - PORTRAIT ( 414 x 896 or 600 x 1024 )
                    @media only screen and (max-width: 768px) and (min-width: 170px) and (orientation: portrait) {
                      width: 50px;
                    }
                    // MOBILE - LANDSCAPE 663 x 662 ( google responsive)
                    @media only screen and (max-height: 768px) and (min-width: 170px) and (orientation: landscape) {
                      width: 50px;
                    }
                  }
                }
                //
                //
                //
                //
                .pagination {
                  display: flex;
                  justify-content: flex-end;
                  align-items: center;

                  // pagination
                  h1 {
                    margin-right: 10px;
                    padding: 10px 0;
                    font-size: calc(6px + 1.1vmin);
                    letter-spacing: 5px;
                    font-family: "Casta-Thin-oblique";
                  }
                }

                //
                //
              }
            }

            //
            //-----------
            // Block right🔶
            //-----------
            //
            .block-right {
              border-left: 1.5px solid #000;

              overflow: hidden;
              display: none;
              flex-direction: column;
              justify-content: space-between; //
              align-items: flex-end;

              //-------------**
              //-------------**
              // TABLET2 - PORTRAIT 994 x 1367
              @media only screen and (max-width: 1366px) and (min-width: 993px) and (orientation: portrait) {
                display: flex;
              }

              // TABLET2 - LANDSCAPE (1024 x 600 [ Desktop/Laptop ])
              @media only screen and (max-height: 1366px) and (min-width: 993px) and (orientation: landscape) {
                display: flex;
              }

              //-------------**
              //-------------**
              // DESKTOP - PORTRAIT
              @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                display: flex;
                justify-content: space-between;
              }

              // DESKTOP - LANDSCAPE
              @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                display: flex;
                justify-content: space-between;
              }

              //

              .img-box-modal {
                width: 80%;
                margin: 0 auto;

                //-------------**
                //-------------**
                // DESKTOP - PORTRAIT
                @media only screen and (max-width: 1666px) and (min-width: 1367px) and (orientation: portrait) {
                  width: 70%;
                }

                // DESKTOP - LANDSCAPE
                @media only screen and (max-height: 1666px) and (min-width: 1367px) and (orientation: landscape) {
                  width: 70%;
                }
                //
                img {
                  border: 1.5px solid #000;
                  border-radius: 280px;
                  aspect-ratio: 1.75/1.2;
                  width: 100%;
                  height: 100%;
                  object-fit: cover;
                  display: block;
                }
              }

              // ------
              //
              .preview-button {
                display: flex;
                justify-content: flex-end;
                align-items: center;
                a {
                  .previewBtn {
                    margin-right: 10px;
                    width: 100px;
                    padding: 5px 26px;
                    //
                    color: #191919;
                    font-size: calc(7px + 1.1vmin);
                    font-weight: 500;
                    border-radius: 50px;
                    border: 1.5px solid #000;
                    cursor: pointer;
                    background-color: transparent;
                    transition: 0.5s all ease-in-out;

                    &:hover {
                      background-color: #191919;
                      color: rgb(251, 246, 233);
                    }
                    //
                    //
                  }
                }
              }
            }
            //

            //
            //
          }
        }
      }
    }
  }
}
