.about-section {
  p {
    font-size: calc(5px + 7vmin);
    margin: 0 10px;

    line-height: 1.13em;
    opacity: 0;
    color: rgb(22, 22, 22);
    //
    //> means for EACH of the div's, for each of the divs it will be opacity 0
    > div {
      opacity: 0;
      transform: translateY(0px);
    }
    //
    // This class is generated after you add the data-scroll in the jsx
    &.is-reveal {
      opacity: 1;
    }
  }
}

@media screen and (min-width: 768px) {
  .about-section {
    p {
      width: 100%;
      line-height: 0.5em;
      color: rgb(22, 22, 22);
      font-size: calc(5px + 7.6vmin);
      line-height: 1.12;
      opacity: 0;

      //
      //> means for EACH of the div's, for each of the divs it will be opacity 0
      > div {
        opacity: 0;
        transform: translateY(0px);
      }
      //
      // This class is generated after you add the data-scroll in the jsx
      &.is-reveal {
        opacity: 1;
      }
    }
  }
}
